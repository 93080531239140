/* MyForm.css */
.wifiassign-container {
    max-width: 400px;
    margin: 8rem auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
  }
  
  .wifiassign-container div {
    margin-bottom: 15px;
  }
  
  .wifiassign-container label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .wifiassign-container input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .wifiassign-container button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .wifiassign-container button:hover {
    background-color: #0056b3;
  }
  