#RoomsdahContainer{
  width: 100vw;
  height: 100vh;
  /* background-color: #050b21;
  color: white; */
  
}


.Roommaincontainer{
  margin-top: 2rem;
  width: 70vw;
  height: auto;
  margin-left: 20rem;
  
}

.Roomupparfiled{
  display: flex; 
 justify-content: space-between;
 align-items: center;
}

.Roomrightside input[type="search"] {
  width: 100%;
  padding: 5px;
  font-size: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.Roomrightside input[type="search"]:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.Roomrightside input[type="search"]::placeholder {
  color: #999;
}

.Roomrightside input[type="search"]:hover {
  border-color: #0056b3;
}


.floor-select{
  width: 100%;
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  margin: auto;
}

.Roomrightside{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.Roomleftside{
  /* margin-top: 1.5rem;
  margin-left: 21.5rem; */
}

.maindiv{
display: flex;
overflow-y: scroll;
width: 70vw;
height: 70vh;
border: 1px solid #dddddd;
padding: 1rem 1rem;
margin-top: 1.5rem;
}


@import url("https://fonts.googleapis.com/css2?family=Baloo+Paaji+2:wght@400;500&display=swap");


.container {
 display: flex;
 flex-wrap: wrap;
 gap: 50px;
 justify-content: center;
 border-radius: 5px solid green;
overflow: hidden;
font-family: 'Baloo Paaji 2', cursive;
}

.card {
  margin-top: 1rem;
  background-color: #050b21;
  border-radius: 5px;
  display: flex;
  width: calc(31.33% - 20px);
  height: 12rem;
  width: 16rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  flex-direction: column;
  align-items: center;
  /* box-shadow: rgba(0, 0, 0, 0.7); */
  color: white;
  
}

.available {
  /* background-color: #4caf50;  */
}

.occupied {
  background-color: red; 
}

.card__name {
  margin-top: 10px;
  font-size: 1.5em;
}


.draw-border {
  box-shadow: inset 0 0 0 4px #58cdd1;
  color: #58afd1;
  -webkit-transition: color 0.25s 0.0833333333s;
  transition: color 0.25s 0.0833333333s;
  position: relative;
}

.draw-border::before,
.draw-border::after {
  border: 0 solid transparent;
  box-sizing: border-box;
  content: '';
  pointer-events: none;
  position: absolute;
  width: 0rem;
  height: 0;
  bottom: 0;
  right: 0;
}

.draw-border::before {
  border-bottom-width: 4px;
  border-left-width: 4px;
}

.draw-border::after {
  border-top-width: 4px;
  border-right-width: 4px;
}

.draw-border:hover {
  color: #ffe593;
}

.draw-border:hover::before,
.draw-border:hover::after {
  border-color: #eb196e;
  -webkit-transition: border-color 0s, width 0.25s, height 0.25s;
  transition: border-color 0s, width 0.25s, height 0.25s;
  width: 100%;
  height: 100%;
}

.draw-border:hover::before {
  -webkit-transition-delay: 0s, 0s, 0.25s;
  transition-delay: 0s, 0s, 0.25s;
}

.draw-border:hover::after {
  -webkit-transition-delay: 0s, 0.25s, 0s;
  transition-delay: 0s, 0.25s, 0s;
}

.btn {
  background: none;
  border: none;
  cursor: pointer;
  line-height: 1.5;
  font: 700 1.2rem 'Roboto Slab', sans-serif;
  padding: 0.5em 1em;
  letter-spacing: 0.02rem;
  margin: 15px;
  width: 10rem;
  overflow: hidden;
}

.btn:focus {
  outline: 2px dotted #55d7dc;
}

.grid-container {
  display: flex;
  gap: 10px;
  font-size: 1.2em;
}

/* .available {
  background-color: green;
  margin-top: 12px;
 width: 10px;
 height: 10px;
 border-radius: 50%;
}

.occupied {
  background-color: red;
  margin-top: 12px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
} */


.dots{
margin-top: 12px;
background: green;
width: 10px;
height: 10px;
border-radius: 50%;
}

@media screen and (max-width: 1062px) {

  .Roommaincontainer{
    margin-left: 10rem;
  }
  .card {
    width: calc(50% - 20px); 
  }
}

@media screen and (max-width: 768px) {

  .Roommaincontainer{
    margin-left: 6rem;
  }
  .card {
    width: calc(50% - 20px); 
  }
}

/* Adjust width and margins for even smaller screens */
@media screen and (max-width: 480px) {
  .card {
    width: calc(100% - 20px); 
  }
}

