
.Maincontai{
   width: 73.5vw;
   height: 100vh;
   margin-left: 25rem;
   margin-top: 2rem;
}

.mainguest-table{
   /* display: flex;
   align-items: center; */
   /* margin: 5rem 5rem; */
}

@media screen and (max-width: 1400px) {

   .Maincontai{
     margin-left: 15rem;
   }

}

@media screen and (max-width: 1200px) {

   .Maincontai{
     margin-left: 15rem;
   }

}

@media screen and (max-width: 900px) {

   .Maincontai{
     margin-left: 8rem;
   }

}