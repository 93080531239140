#main-container {
    height: 100vh;
    width: 100vw;
    background-image: url(../Assets/maldives-island.jpg);
    /* background-size: 100% 100%; */
    background-size: cover;
    background-repeat: no-repeat;
    
}

.signup-form-containers {
    margin-top: 3rem;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    float: right;
    width: 45rem;
    height: auto;
    margin-right: 2rem;
    background-color: #f2f2f2;
}

.foam-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    /* font-family: "Noto Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800; */
    size: large;
}

.signup-form-containers h2 {
    margin-top: 1rem;
    font-family: "Nunito", sans-serif;
    font-weight: 700;
    /* width: 500px; */
    /* font-family: "Baloo 2", sans-serif; */

}

.signup-form-containers h3 {
    /* text-decoration: underline; */
    font-size: larger;
    /* font-family: "Baloo 2", sans-serif; */
    font-family: "Nunito", sans-serif;
    font-weight: 700;
}

.form-groups {
    margin-bottom: 10px;
}

/* .row{
    display: flex;
    flex-direction: column;
    
} */


.row-gap {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
    /* font-size: small; */
    font-weight: 700;
    /* font-family: "Poppins", sans-serif; */

    /* font-style: normal; */


}

.error {
    color: red;
    font-size: 14px;
}

.form-groups input[type="text"],
.form-groups input[type="email"],
.form-groups input[type="Number"],
.form-groups input[type="password"] {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: small;
    font-weight: 400;
    color: black;
    font-family: "Fredoka";
    /* border-color: black; */
    /* border-radius: 12px; */
    outline: none;
    background: transparent;
}

.form-groups button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 1.5rem;
}

button:hover {
    background-color: #0056b3;
}

#footer-section {
    display: flex;
    margin-top: 2rem;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Respo\\nsive styles */
@media (max-width: 768px) {

/* #main-container{
    background-size: cover;
} */

    .signup-form-containers{
        margin-right: 0;
    }
    .signup-form-container {
        float: none;
        margin: auto;
    }
}