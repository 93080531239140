#form-container {
  width: 100vw;
  height: 100vh;
 
}

.form-seconfcontainer {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  margin-left: 20rem;

}


.form-seconfcontainer h1 {
  /* margin-right: 24.6rem; */
  display: flex;
  align-items: end;
  padding-top: 2rem;
}



.form-group {
  display: flex;
  overflow-y: scroll;
  /* margin: 2rem; */
  /* justify-content: space-between; */
  width: 70vw;
  height: 70vh;
  border: 2px solid #ddd;
  padding: 1rem 1rem;
  margin-top: 1.5rem;
  margin-right: 3rem;
}


.input-field input,
.room-price ,
.input-field select
{
  margin-left: 3rem;
  width: 25rem;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: gray;
  background-color: white;
  font-family: inherit;
  font-weight: 450;
  border-bottom: 2px solid gray;
  font-size: 1rem;
  transition: border-color 0.2s;
  font-family: "Fredoka";
}

.input-field p {
  margin-left: 3rem;
  margin-top: 5px;
  color: rgb(23, 203, 23);
}
.input-field ::placeholder {
  /* font-weight: 450;
    font-family: "Fredoka"; */
}

.input-div {
  display: flex;
  gap: 5rem;
}

/* input[type="text"],
  input[type="email"],
  input[type="date"],
  input[type="number"],
  button {
    width: 70%; 
    float: right; 
  }
   */
.input-field button {
  /* margin: 1rem 0; */
  margin-top: 10px;
  margin-bottom: 1rem;
  margin-left: 3rem;
  cursor: pointer;
  position: relative;
  padding: 10px 30px;
  font-size: 18px;
  color: rgb(21, 52, 118);
  border: 2px solid rgb(20, 79, 133);
  border-radius: 10px;
  background-color: transparent;
  font-weight: 600;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.320, 1);
  overflow: hidden;
}

.input-field button::before {
  content: '';
  position: absolute;
  inset: 0;
  margin: auto;
  width: 50px;
  height: 50px;
  border-radius: inherit;
  scale: 0;
  z-index: -1;
  background-color: rgb(81, 121, 201);
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.input-field button:hover::before {
  scale: 3;
}

.input-field button:hover {
  color: #13357a;
  scale: 1.1;
  box-shadow: 0 0px 20px rgba(193, 163, 98, 0.4);
}

.input-field button:active {
  scale: 1;
}

.input-field ul{
  margin-top: 0;
  /* padding-left: 0; */
padding: 5px 5px;
  width: 92%;
  /* display: flex;
  flex-direction: column; */
  /* right: 5rem; */
  /* background-color: aqua; */
  margin-left: 2.6rem;
  overflow-y: scroll;
  height: 5rem;

  /* border: 2px solid black; */

}

.input-field ul li{
  border-bottom: 2px solid black;
  background: #eee;
  /* padding: 2px 0; */
  margin-bottom: 2px;
  border-radius: 2px;
}



/* .dropdown {
  position: relative;
}

.dropdown-row {
  display: none;
  position: absolute;
  background-color: #960707;
  min-width: 100%;
  z-index: 1;
  border: 1px solid #ddd;
  border-top: none;
}

.dropdown-row:hover {
  background-color: #f1f1f1;
}

.dropdown.active .dropdown-row {
  display: block;
} */

/* .sugesstion buttons {
  margin-top: 0px;
  margin-bottom: 0;
  width: 1rem;
  height: 1rem;
  position: fixed;
} */

/* sugesstion button */

/* registerd guest css */

.modal {
  position: fixed;
  top: 2rem;
  /* bottom: 2rem; */
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  display: flex;
  flex-direction: column;
  z-index: -1;
  /* justify-content: center;
  align-items: center; */
  /* align-items: start; */
  background: #fff;
  padding: 2rem;
  border-radius: 10px;
  width: 700px;
  height: auto;
  /* max-width: 80%; */
  position: relative;
}

.modal-content h2 {
  margin-top: 0;
  margin-bottom: 1rem ;
  margin-left: 3rem;
}

.modal-content .input-field {
  margin-bottom: 0.2rem;
}

.modal-content .input-field input {
  width: 34rem;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.modal-content .input-field button {
  margin-right: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  background: #007bff;
  color: #fff;
  cursor: pointer;
  transition: background 0.3s;
}

.modal-content .input-field button:hover {
  background: #0056b3;
}

.modal-content .input-field button[type="button"] {
  background: #6c757d;
}

.modal-content .input-field button[type="button"]:hover {
  background: #5a6268;
}

.newGuestError{
  color: red;
  margin-left: 3rem;
}



@media screen and (max-width: 768px) {

  .form-seconfcontainer {
    margin-left: 8rem;
  }

  .input-div {
    flex-direction: column;
    gap: 1rem;
  }

  #form-container h2 {
    /* margin-left: 6rem;
    padding-top: 2rem; */
  }
}