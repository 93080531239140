.user-formss {
    max-width: 400px;
    margin: auto;
    margin-top: 7rem;
    padding: 2rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .form-groupss input
   {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }

  .form-groupss select{
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    color: gray;
    background-color: white;
    font-family: inherit;
    font-weight: 450;
    border-bottom: 2px solid gray;
    font-size: 1rem;
    transition: border-color 0.2s;
    font-family: "Fredoka";
    margin-bottom: 1rem;
  }

  
  #addemployeebutton {
    display: block;
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  