#maincomponent{
  width: 100vw;
  height: 100vh;
}

#checkout-containe {
  width: 100vw;
  height: auto;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.checkout-form {
  margin-left: 20rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 45rem;
  padding: 10px 10px;
  /* background-color: rgb(239, 188, 130); */
}

h1 {
  text-align: center;
}

.form-group {
  margin-top: 10px;
}

.input-fiel {
  margin-bottom: 15px;
}

.input-fiel label {
  display: block;
  margin-bottom: 5px;

}

.input-fiel input[type="text"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.payment-method select,
.payment-status select {
  margin: 0.8rem 0;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}



.input-fie button {
  background-color: #4c6baf;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.payment-option{
  display: flex;
  justify-content: space-between;
}

.payment-status input{
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.Paid-button{
  margin-top: 38px;

}

.Paid-button button{
  /* color: white; */
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  background-color: rgb(102, 102, 236);
}

.printbutton button{
  display: flex;
  float:right;
  /* margin: 30rem ; */
  /* justify-content: center;
  align-items: center; */
}