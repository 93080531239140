.thank-you-page {
    background-image: url(../Assets/maldives-island.jpg);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f5f5f5;
    padding: 20px;
  }
  
  .thank-you-page h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .thank-you-page p {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .thank-you-page a {
    color: #007bff;
    text-decoration: none;
  }
  
  .thank-you-page button {
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .thank-you-page button:hover {
    background-color: #0056b3;
  }
  