.sliding-menu {
    position: fixed;
    top: 0;
    left: -300px; 
    width: 250px;
    height: 100%;
    transition: left 0.3s ease;
}

.open {
    left: 0; 
}

.menu-content {
    padding: 20px;
}

.menu-content ul {
    list-style-type: none;
    padding: 0;
    margin-top: 5rem;
    justify-content: space-between;
}

li {
    padding: 0px 15px;
    transition: left 0.8s ease;
}

#cross-icon {
    border: none; 
    cursor: pointer;
    margin-top: 1rem;
    padding: 10px 20px;
    float: right;
}



.siderbarcontent{
    display: flex;
    padding: 10px 0px;
}

.siderbarcontent:hover{
    border-radius: 5px;
}


  .sidebartools{
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px ;
    border-color: #5e69dd;
  }
