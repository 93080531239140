#LoginContainer{
    height: 100vh;
    width: 100vw;
    background-image: url(../Assets/maldives-island.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

.loginmaindiv{
    padding: 20px;
    height: 80%;
    width: 43rem;
    float: right;
    margin-top: 5rem;
    background-color: #f2f2f2;  
    margin-right: 2rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    /* margin-right: 3rem; */
}

.login-headers{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 2rem;
    align-items: center;
}

.login-bottom{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    flex-direction: column;
    gap: 2rem;
    
    
    
}


@media (max-width: 768px) {
    .loginmaindiv{
        margin-right: 0;
    }
   
   
}

