#navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 20px;
    height: 3.5rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

nav {
    padding: 0px 0;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .nav-leftside{
    display: flex;
    gap: 2rem;
  }

  .nav-leftside > :first-child {
    margin-top: 5px;
  }

  .hamburger {
    cursor: pointer;
    margin-left: 1rem;
}

  .nav-leftside:first{
    margin-left: 1rem;
    font-size: 1.5rem;
    margin-top: 10px;
  }

  
  .navbar-brand {
    font-size: 30px;
  
  }
  
  
  .navbar-icons {
    display: flex;
    gap: 10px;
  }
  
  .navbar-icons a{
  font-weight: 700;
  text-decoration: none;
  }
  
  
  .icons {
    padding: 0rem 2rem;
    cursor: pointer;
  }
  



