/* .signup-form-container {
    background-color: #f2f2f2;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    float: right;
    width: 300px;
} */

/* .form-groups {
    margin-bottom: 10px;
} */
.rows{
    display: flex;
    justify-content: space-between;
    
}

label {
    display: block;
    margin-bottom: 5px;
}


input[type="file"] {
    margin-bottom: 10px;
}

/* button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
} */

button:hover {
    background-color: #0056b3;
}
