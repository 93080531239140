.hotelist-container{
    /* float: left; */
    /* background-color: rgb(240, 243, 229); */
    height: 100%;
    /* width: 100%; */
}

.hotellistbox{
  /* border: 2px solid black; */
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 3rem;
  float: right;
  margin-right: 5rem;
  /* position: relative */
}

.hotel-list {
    width: 100%;
    padding: 20px;
  }
  
  .hotellistbox input[type="text"] {
    padding: 8px;
    margin-bottom: 20px;
    font-size: 16px;
    align-self:flex-start;
    /* margin-left: 14.4rem; */
    border-radius: 12px;
  }
  
  .table-container {
    /* max-height: 400px; */
    /* overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 5px; */
  }
  
  table {
    /* margin-top: 5rem; */
    /* width: 50rem; */
    border: 2px sold black;
    width: 70vw;
    border-collapse: collapse;
    align-self: center;
  }
  
  table th, table td {
    width: 50rem;
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid black;
  }
  
  table th {
    background-color: #f4f4f4;
  }
  
 .hotellistbox button {
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
  }
  