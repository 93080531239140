.user-verification-form {
    max-width: 400px;
    margin: auto;
    margin-top: 11rem;
    padding: 2rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .form-groups {
    margin-bottom: 1rem;
  }
  
  .form-groups label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .form-groups input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .error {
    color: red;
    font-size: 0.875rem;
  }
  
  #verication-button {
    display: block;
    width: 100%;
    height: 2.5rem;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  