/* Navbar.css */
.navbars {
    height: 4.5rem;
    position: relative;
    background-color: #333;
    color: white;
    padding: 10px;
  }
  
  .navbar-contents {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    align-items: center;
  }
  
  .search-containers {
    flex-grow: 1;
  }
  
  .search-input {
    width: 20%;
    padding: 5px;
    border-radius: 3px;
    border: none;
    float: right;
  }
  
  .hamburgers {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 30px;
  }
  
  .hamburgers .bar {
    width: 100%;
    height: 3px;
    background-color: white;
    transition: 0.3s;
  }
  
  .side-menus {
    position: fixed;
    right: -250px;
    top: 0;
    height: 100%;
    width: 250px;
    background-color: #444;
    color: white;
    padding: 20px;
    transition: right 0.3s;
  }

  #sidemenus p {
    margin-top: 2rem;
    /* justify-content: center; */
  }
  
  .side-menu.open {
    left: 0;
  }
  